import React, { FC } from 'react'

import {
  AirportShuttle,
  InfoOutlined,
  LocalGasStation,
  PlaylistAddCheck,
  Store,
} from '@mui/icons-material'

import TooltipIcon from '../../../../ui/ToolTipIcon/ToolTipIcon'

import styles from './styles.module.scss'

type TooltipIconsProps = {
  nominatedBarges: boolean
  tanks: boolean
  openHopper: boolean
  havingTboInfo: boolean
  placedToLoad: boolean
  shuttleMoves: boolean
}

const TooltipIcons: FC<TooltipIconsProps> = ({
  tanks,
  openHopper,
  havingTboInfo,
  placedToLoad,
  shuttleMoves,
}) => {
  const renderTooltipMessage = (isActive: boolean, name: string) => {
    return `${name}: ${isActive ? 'Included' : 'Excluded'}`
  }

  return (
    <div className={styles.iconContainer}>
      <TooltipIcon isActive={tanks} tooltip={renderTooltipMessage(tanks, 'Tanks')} icon={<LocalGasStation />} />
      <TooltipIcon isActive={openHopper} tooltip={renderTooltipMessage(openHopper, 'Open Hopper')} icon={<Store />} />
      <TooltipIcon
        isActive={havingTboInfo}
        tooltip={renderTooltipMessage(havingTboInfo, 'Having TBO Info')}
        icon={<InfoOutlined />}
      />
      <TooltipIcon
        isActive={placedToLoad}
        tooltip={renderTooltipMessage(placedToLoad, 'Placed to Load')}
        icon={<PlaylistAddCheck />}
      />
      <TooltipIcon
        isActive={shuttleMoves}
        tooltip={renderTooltipMessage(shuttleMoves, 'Shuttle Moves')}
        icon={<AirportShuttle />}
      />
    </div>
  )
}

export default TooltipIcons
